import React from "react";
import { Box, Image, Link } from "@chakra-ui/react";
import "./style.scss";

export default function WppButton() {
  return (
    <Box position={"fixed"} right={"55px"} bottom={"57px"} zIndex={4000}>
      <Box className="wpp-button"></Box>
      <Box className="wpp-button-background"></Box>
      <Link
        href="https://api.whatsapp.com/send?1=pt_BR&phone=5585986962631"
        target="_blank"
      >
        <Image
          _hover={{ cursor: "pointer" }}
          position={"relative"}
          zIndex={400}
          src="./img/social-media/wpp-big.svg"
          alt="wpp-button"
          w={{ base: "66px", lg: "96px" }}
          h={{ base: "66px", lg: "96px" }}
        />
      </Link>
    </Box>
  );
}
