import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { hydrateRoot } from "react-dom";

const theme = extendTheme({
  fonts: {
    heading: "Libre Baskerville, serif",
    body: "IBM Plex Sans, sans-serif",
  },
  styles: {
    global: {
      body: {
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight: 500,
        color: "#243C58",
      },
    },
  },
  components: {
    Heading: {
      baseStyle: { color: "#1E324A", fontFamily: "Libre Baskerville, serif" },
    },
  },
});

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  );
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  );
}
