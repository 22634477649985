import React from "react";
import { Box, HStack, Image, Link } from "@chakra-ui/react";

function NavLink({ text, href }) {
  return (
    <Link
      fontFamily={"IBM Plex Sans"}
      fontSize={"20px"}
      fontWeight={"500"}
      lineHeight={"40px"}
      textAlign={"center"}
      href={href}
      textColor={"#243C58"}
      _hover={{ color: "#1f69bf", textDecoration: "none" }}
    >
      {text}
    </Link>
  );
}

export default function Header() {
  return (
    <Box
      display={{ base: "none", xl: "block" }}
      height={"100px"}
      backgroundColor={"#FFFFFF99"}
      position={"fixed"}
      width={"100%"}
      zIndex={"1000"}
      mixBlendMode={"normal"}
      backdropFilter={"blur(8px)"}
    >
      <HStack height={"100%"} justifyContent={"space-evenly"}>
        <Link href="#home">
          <Image
            src={"./img/logo164.png"}
            width={"78px"}
            height={"82px"}
            _hover={{
              filter:
                "invert(33%) sepia(50%) saturate(1709%) hue-rotate(188deg) brightness(92%) contrast(92%)",
              textDecoration: "none",
            }}
          ></Image>
        </Link>

        <NavLink text={"Apresentação"} href={"#apresentacao"} />
        <NavLink text={"O Mentor"} href={"#mentor"} />
        <NavLink text={"Missão e Valores"} href={"#missaoevalores"} />
        <NavLink text={"Metodologia e Diferencial"} href={"#metodologia"} />
        <NavLink text={"Produtos"} href={"#produtos"} />
        <NavLink text={"Principais Benefícios"} href={"#beneficios"} />
        <NavLink text={"Contatos"} href={"#contatos"} />
      </HStack>
    </Box>
  );
}
