import { Box, Image } from "@chakra-ui/react";
import React from "react";

export default function RoundedAlmir() {
  return (
    <>
      <Box
        h={{ lg: "440px" }}
        w={{ lg: "440px" }}
        borderRadius={"50%"}
        border={"1px solid #EFF2F5"}
        overflow={"hidden"}
        position="absolute"
        top={"50%"}
        left={"50%"}
        transform={{
          lg: "translate(-43%, -38%)",
          // "2xl": "translate(-43%, -38%)",
        }}
        zIndex={"-1"}
        backgroundColor="#32ADE60D"
        mixBlendMode={"normal"}
        backdropFilter={"blur(4px)"}
        display={{ base: "none", xl: "block" }}
      >
        <Image
          src="./img/almir-sitting.png"
          h={{ lg: "720px" }}
          objectFit={"cover"}
          objectPosition={{ lg: "-20px -32px" }}
        />
      </Box>
      {/* <Image
        src="./img/mentor/rounded-almir.png"
        display={{ base: "block", lg: "none" }}
        objectFit={"cover"}
      /> */}
      <Box
        h={{ base: 150, lg: 300 }}
        w={{ base: 150, lg: 300 }}
        borderRadius={"50%"}
        border={"1px solid #EFF2F5"}
        overflow={"hidden"}
        top={"50%"}
        left={"50%"}
        zIndex={"-1"}
        mixBlendMode={"normal"}
        backdropFilter={"blur(4px)"}
        display={{ base: "block", xl: "none" }}
      >
        <Image
          src="./img/almir-sitting.png"
          h={{ base: "260px", lg: "520px" }}
          objectFit={"cover"}
          objectPosition={{ base: "-10px -16px", lg: "-20px -32px" }}
        />
      </Box>
    </>
  );
}
