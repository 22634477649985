import React from "react";

import { Image, Box, HStack, VStack, Text, Flex } from "@chakra-ui/react";

const contacts = [
  {
    name: "@almir.floretmentor",
    icon: "./img/social-media/instagram.svg",
    href: "https://www.instagram.com/almir.floretmentor/",
  },
  {
    name: "almir.cardoso@floretmentor.com",
    icon: "./img/social-media/email.svg",
    href: "mailto:almir.cardoso@floretmentor.com",
  },
  {
    name: "+55 85 98696-2631",
    icon: "./img/social-media/whatsapp.svg",
    href: "https://api.whatsapp.com/send?1=pt_BR&phone=5585986962631",
  },
];

export default function Footer() {
  return (
    <Box width={"100%"} id="contatos">
      <VStack
        bg={"#243C58"}
        width={"100%"}
        h={"100%"}
        spacing={10}
        pt={"27px"}
        pb={"64px"}
        px={{ base: "18px", lg: "0" }}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <img src="./img/logo-vertical-s-r.svg" alt="logo" />

        <Flex
          justifyContent={"center"}
          direction={{ base: "column", lg: "row" }}
          alignItems={"center"}
          gap={6}
          pt={"32px"}
          color={"#D5E2E8"}
        >
          {contacts.map((contact) => (
            <a href={contact.href} target="_blank" rel="noreferrer">
              <HStack key={contact.name}>
                <Image
                  src={contact.icon}
                  alt={contact.name}
                  w={"32px"}
                  h={"32px"}
                />
                <Text fontWeight={500} fontSize={"21px"}>
                  {contact.name}
                </Text>
              </HStack>
            </a>
          ))}
        </Flex>
      </VStack>
      <Box bg={"#000000"} h={"136px"} w={"100%"}>
        <HStack w={"100%"} h="100%" justifyContent={"center"}>
          <Text fontWeight={500} fontSize={"16px"} color={"#F2DB95"}>
            Design por
          </Text>
          <Text fontWeight={500} fontSize={"24px"} color={"#F2DB95"}>
            rangelsouza.com.br
          </Text>
        </HStack>
      </Box>
    </Box>
  );
}
