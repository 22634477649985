import React from "react";
import {
  Image,
  Box,
  VStack,
  Heading,
  Text,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import CardWhite from "../../components/card/CardWhite";

export default function Benefits() {
  return (
    <Box
      position="relative"
      overflow="hidden"
      id="beneficios"
      pb={{ base: "40px", lg: "198px" }}
      mb={"-200px"}
      top={"-200px"}
      // height={"1060px"}
      zIndex={"0"}
      // clipPath={{
      //   base: "polygon(0 0, 100% 70px, 100% 100%, 0 100%)",
      //   lg: "polygon(0 0, 100% 140px, 100% 100%, 0 100%)",
      // }}
    >
      <Image
        src={"./img/bg-mentor.png"}
        position="absolute"
        width={{ lg: "100%" }}
        height="100%"
        objectFit={"cover"}
        objectPosition={{ base: "42% 0px", lg: "50% 0px" }}
        zIndex={"-100"}
        display={{ base: "block", lg: "block" }}
      />
      {/* <Image
        src={"./img/bg-mentor.png"}
        display={{ base: "none", lg: "block" }}
        position="absolute"
        top={"50%"}
        left={"50%"}
        transform={"translate(-50%, -50%)"}
        minWidth={"100%"}
        height="auto"
        zIndex={"-100"}
        width={"100%"}
      /> */}
      <Heading
        as="h1"
        height="481.48px"
        fontSize="388.29px"
        lineHeight="31.06px"
        textOverflow="revert"
        position="absolute"
        fontWeight={"400"}
        top="280"
        left="-5"
        width="100vw"
        whiteSpace="nowrap"
        zIndex="-10"
        color={"#D5E2E8CC"}
        display={{ base: "none", xl: "block" }}
      >
        Benefícios
      </Heading>
      <VStack mt={{ base: "240px", lg: "189px" }} w="100%">
        <Heading
          fontSize={{ base: "32px", lg: "102px" }}
          fontWeight={"400"}
          w={"100%"}
          textAlign={"center"}
          mb={{ base: "40px", lg: "0" }}
        >
          Principais Benefícios
        </Heading>

        <Text
          w={{ base: "100%", lg: "875px" }}
          align={"center"}
          fontWeight={"500"}
          fontSize={{ base: "16px", lg: "24px" }}
          lineHeight={{ base: "24px", lg: "40px" }}
          p={{ base: "0 25px", lg: "0" }}
          color={"#000000"}
        >
          Os Principais Benefícios dos processos de desenvolvimento humano
          oferecidos pela Floret focam a inteligência emocional de líderes e
          gestores e impactam significativamente a eficácia do exercício da
          função. Alguns dos principais benefícios são:
        </Text>

        <Flex
          w={"100%"}
          justifyContent={"space-evenly"}
          mt={{ base: "40px", lg: "100px" }}
          // mb={"100px"}
          px={{ base: "32px", lg: "0" }}
          gap={{ base: "32px", lg: "100px" }}
          direction={{ base: "column", lg: "row" }}
        >
          <Box>
            <CardWhite width={"100%"} height={"100%"}>
              <Flex
                height={"100%"}
                width={"100%"}
                alignItems={"center"}
                direction={"column"}
                gap={"32px"}
                px={2}
                pt={5}
                pb={10}
              >
                <Text
                  lineHeight={"40px"}
                  fontSize={"32px"}
                  fontWeight={{ base: 400, lg: 700 }}
                  align={"center"}
                >
                  Benefícios Intrapessoais
                </Text>
                <UnorderedList
                  color={"#243C58"}
                  fontSize={{ base: "16px", lg: "24px" }}
                  fontWeight={"500"}
                  display={"flex"}
                  flexDir={"column"}
                  alignItems={"center"}
                  textAlign={"center"}
                >
                  <ListItem>Melhor Autoconsciência</ListItem>
                  <ListItem>Autorregulação Eficiente</ListItem>
                  <ListItem>Aumento das capacidades cognitivas</ListItem>
                  <ListItem>Pensamento Estratégico</ListItem>
                  <ListItem>Proatividade</ListItem>
                </UnorderedList>
              </Flex>
            </CardWhite>
          </Box>

          <Box>
            <CardWhite width={"100%"} height={"100%"}>
              <Flex
                height={"100%"}
                width={"100%"}
                alignItems={"center"}
                direction={"column"}
                gap={"32px"}
                px={2}
                pt={5}
                pb={10}
              >
                <Text
                  lineHeight={"40px"}
                  fontSize={"32px"}
                  fontWeight={{ base: 400, lg: 700 }}
                  align={"center"}
                >
                  Benefícios Interpessoais
                </Text>
                <UnorderedList
                  color={"#243C58"}
                  fontSize={{ base: "16px", lg: "24px" }}
                  fontWeight={"500"}
                  display={"flex"}
                  flexDir={"column"}
                  alignItems={"center"}
                  textAlign={"center"}
                >
                  <ListItem>Aumento da Empatia</ListItem>
                  <ListItem>Habilidades Sociais Aprimoradas</ListItem>
                  <ListItem>Comunicação Eficiente</ListItem>
                  <ListItem>Adaptação aos Desafios</ListItem>
                  <ListItem>Tomadas de Decisão Assertivas</ListItem>
                </UnorderedList>
              </Flex>
            </CardWhite>
          </Box>
        </Flex>
      </VStack>
    </Box>
  );
}
