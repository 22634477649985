import React from "react";
import {
  Image,
  Box,
  VStack,
  Heading,
  Text,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import CardWhite from "../../components/card/CardWhite";
import Benefits from "../benefits/Benefits";

const PRODUCTS = [
  {
    title: "PRODUTOS INDIVIDUAIS",
    products: [
      {
        title: "CONSULTORIA INDIVIDUAL",
        image: "./img/products/socorro.png",
        description: [
          "Análise de perfil temperamental;",
          "Análise de desenvolvimento da inteligência emocional;",
          "Prognóstico de desenvolvimento pessoal (temperamento, inteligência emocional e aparato cognitivo comportamental).",
        ],
      },
      {
        title: "MENTORIA INDIVIDUAL",
        image: "./img/products/mentoria.png",
        description: [
          "Análise de perfil temperamental;",
          "Análise de desenvolvimento da inteligência emocional;",
          "Prognóstico de desenvolvimento pessoal (temperamento, inteligência emocional e aparato cognitivo comportamental).",
          "Processo com acompanhamento de evolução personalizado;",
          "Aplicação de ferramentas adequadas ao processo de desenvolvimento da inteligência emocional e de habilidades comportamentais;",
          "Desenvolvimento das capacidades pessoais ligadas à percepção e à tomada de decisão;",
          "Alinhamento de habilidades voltadas às relações interpessoais e ao exercício das funções profissionais.",
        ],
      },
    ],
  },
  {
    title: "PRODUTOS COLETIVOS",
    products: [
      {
        title: "CONSULTORIA COLETIVA",
        image: "./img/products/mentor-coletivo.png",
        description: [
          "Análise de perfil temperamental da equipe;",
          "Análise de equilíbrio funcional DISC da equipe;",
          "Análise de inteligência emocional pessoal dos membros da equipe;",
          "Prognóstico de desenvolvimento humano da equipe (temperamentos, inteligência emocional e aspectos cognitivos comportamentais).",
        ],
      },
      {
        title: "MENTORIA COLETIVA",
        image: "./img/products/mentor-coletivo-2.png",
        description: [
          "Análise de perfil temperamental da equipe;",
          "Análise de equilíbrio funcional DISC da equipe;",
          "Análise de inteligência emocional pessoal dos membros da equipe;",
          "Prognóstico de desenvolvimento humano da equipe (temperamentos, inteligência emocional e aspectos cognitivos comportamentais);",
          "Processo de evolução conjunta e colaborativa;",
          "Aplicação de ferramentas adequadas ao desenvolvimento da inteligência emocional e de habilidades comportamentais da equipe;",
          "Desenvolvimento das capacidades pessoais ligadas à percepção e à tomada de decisão no âmbito coletivo;",
          "Alinhamento de habilidades voltadas às relações interpessoais e ao exercício das funções profissionais em equipe.",
        ],
      },
    ],
  },
  {
    title: "EVENTOS E CONVENÇÕES",
    products: [
      {
        title: "WORKSHOPS",
        image: "./img/products/mentor-coletivo.png",
        description:
          "Treinamentos para equipes focados na abordagem de temas e no desenvolvimento de habilidades práticas voltadas ao âmbito interpessoal coletivo.",
      },
      {
        title: "PALESTRAS",
        image: "./img/products/palestra.png",
        description:
          "Momentos formativos e motivacionais sobre temas vinculados à realidade humana.",
      },
      {
        title: "INSTRUTORIA",
        image: "./img/products/apresentacao.png",
        description:
          "Momentos formativos de caráter mais complexo, focados no conhecimento e no aprofundamento de temáticas sobre a realidade humana.",
      },
    ],
  },
];

export default function Products() {
  return (
    <Box
      position="relative"
      overflow="hidden"
      id="produtos"
      zIndex={"0"}
      mt={5}
      // clipPath={{
      //   base: "polygon(0 0, 100% 70px, 100% 100%, 0 100%)",
      //   lg: "polygon(0 0, 100% 70px, 100% 100%, 0 100%)",
      // }}
    >
      <Image
        src={"./img/produtos-bg.png"}
        position="absolute"
        height={"100%"}
        objectFit={"cover"}
        width={"100%"}
        left={0}
        zIndex={"-100"}
      />
      <Box px={{ base: "32px", lg: "120px" }}>
        <VStack
          alignItems={"center"}
          width={"100%"}
          justifyContent={"center"}
          gap={{ base: "19.5px", lg: "128px" }}
          mt={{ base: "0", lg: "250px" }}
          zIndex={"100"}
          position={"relative"}
        >
          <Heading
            as="h1"
            height="481.48px"
            fontSize="388.29px"
            lineHeight="31.06px"
            textOverflow="revert"
            position="absolute"
            fontWeight={"400"}
            top="0"
            left="-5"
            width="100%"
            whiteSpace="nowrap"
            zIndex="-10"
            color={"#051739"}
            // opacity={"50%"}
            // blendMode={"color-burn"}
            display={{ base: "none", lg: "block" }}
          >
            Produtos
          </Heading>

          <Heading
            as="h1"
            mt={"94px"}
            lineHeight={"53px"}
            fontWeight={"400"}
            color={"white"}
            fontSize={{ base: "40px", lg: "94px" }}
          >
            Produtos Floret
          </Heading>

          <VStack w="100%" gap={"64px"}>
            {PRODUCTS.map((product) => (
              <VStack w="100%" gap={"32px"}>
                <Heading
                  fontFamily={"body"}
                  color={"white"}
                  fontWeight={500}
                  fontSize={{ base: "24px", lg: "40px" }}
                >
                  {product.title}
                </Heading>

                <Flex
                  alignItems={"stretch"}
                  justifyContent={"space-between"}
                  direction={{ base: "column", xl: "row" }}
                  gap={{ base: "32px", lg: "120px" }}
                  w={"100%"}
                >
                  {product.products.map((subProduct) => (
                    <Box width={"100%"}>
                      <CardWhite height={"100%"}>
                        <VStack p="32px" gap={"32px"} w={"100%"}>
                          <Image src={subProduct.image} w="96px" h="96px" />
                          <Heading
                            fontFamily={"body"}
                            fontWeight={500}
                            color={"#263340"}
                            w={"100%"}
                            textAlign={"center"}
                          >
                            {subProduct.title}
                          </Heading>

                          {typeof subProduct.description === "string" ? (
                            <Text
                              fontFamily={"body"}
                              fontWeight={500}
                              color={"#4C6580"}
                            >
                              {subProduct.description}
                            </Text>
                          ) : (
                            <UnorderedList color={"#4C6580"}>
                              {subProduct.description.map((item) => (
                                <ListItem>{item}</ListItem>
                              ))}
                            </UnorderedList>
                          )}
                        </VStack>
                      </CardWhite>
                    </Box>
                  ))}
                </Flex>
              </VStack>
            ))}
          </VStack>
        </VStack>
      </Box>

      <Benefits />
    </Box>
  );
}
